import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { parseHtml } from "../../utils/parsehtml"
import { SvgUrl } from "@components/_v2/_shared/svg-url"
import LottieAnimation from "@components/LottieAnimation"
import { DevsquadLandingPageContext } from "../../context"
import DEVELOER_ANIMATION from "./assets/developer-animation.json"
import { Container, Space, Box, Text, Button, Stack, Icon } from "@x-apps-projetos/design-system"

export const SectionHotSkills = React.memo(({ lp, content }) => {

  const { navigateToMarket } = React.useContext(DevsquadLandingPageContext)

  return (
    <Box id="section-hot-skills" xs={{
      "width": "100%",
      "background": "primary",
      "marginTop": `92px`
    }}>
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space xs={{ "py": "64", "expand": true }}>
          <Stack xs={{ "direction": "row", "align": "center", "spacing": "64" }}>
            <Box
              xs={{ "marginTop": `-160px` }}
            >
              <LottieAnimation
                width={"100%"}
                height={"100%"}
                animation={DEVELOER_ANIMATION}
              />
            </Box>
            <Stack xs={{ "direction": "column", "spacing": "32", "align": "center", "wrap": true }}>
              <Text
                as="div"
                xs={{ color: "white", "align": "center", "variant": "h4" }}
                value={parseHtml(content.description)}
              />
              <Stack xs={{ "direction": "row", "spacing": "32", "justify": "center", "align": "center" }}>
                {content.list_skills.map(skill => (
                  <a
                    style={{ cursor: "pointer", outline: "none" }}
                    onClick={() => navigateToMarket([skill])}
                  >
                    <Stack xs={{ "direction": "column", "spacing": "12", "align": "center", "justify": "center" }}>
                      <SvgUrl
                        size={32}
                        color="#ffffff"
                        key={`skill-${skill.id}`}
                        url={`https://x-apps.com.br/contrate-squads/logos/${skill.id}.svg`}
                      />
                      <Text
                        xs={{ "color": "white", "wrap": true, "variant": "body_2" }}
                        value={skill.nome}
                      />
                    </Stack>
                  </a>
                ))}
              </Stack>
              <Stack xs={{
                "wrap": true,
                "spacing": "8",
                "align": "center",
                "direction": "row",
                "justify": "center"
              }}>
                <Icon iconName="FiEye" size={21} color="#ffffff" />
                <Text
                  as="span"
                  xs={{ color: "white" }}
                  value={`15 ${content.online_customers_label}`}
                />
              </Stack>
              <Button
                wrap={true}
                color="secondary-gradient"
                label={content.cta_button_label}
                onClick={() => navigateToMarket(lp.related_skills)}
              />
            </Stack>
          </Stack>
        </Space>
      </Container>
    </Box>
  )
})