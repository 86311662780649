import React from "react"
import { DevsquadLandingPageContext } from "./context"
import { obterLinkParaSkills } from "../../../utils/obterLinkParaSkills"

export const DevsquadLandingPageController = ({ children }) => {

  const navigateToMarket = (relatedSkills = []) => {
    window.location.href = obterLinkParaSkills(relatedSkills)
  }

  const state = {
    navigateToMarket
  }

  return (
    <DevsquadLandingPageContext.Provider value={state}>
      {children}
    </DevsquadLandingPageContext.Provider>
  )
}