import styled from "@emotion/styled"
import { Box } from "@x-apps-projetos/design-system"
import backgroundImage from "./assets/background.png"
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #00346B;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const TechLogoContainer = styled(Box)`
  border-radius: 999px;
  transition: transform 0.26s ease, visibility 0s, opacity 0.2s linear;
  transform-origin: top;
  visibility: visible;
  transform: scaleY(1);  
`

export const SubtitleContainer = styled.div`
  font-family: "x-apps";
  display: flex;
  flex-direction: column;
  gap: 24px;
  & * { color: white !important; }
  & h1 { font-weight: bold; font-size: 56px; line-height: 70px; letter-spacing: 0.4px; }
  & h2 { font-weight: bold; font-size: 40px; line-height: 46px; letter-spacing: 0.2px; }
  & h3 { font-weight: 400; font-size: 28px; line-height: 34px; letter-spacing: 0.2px; }
  & h4 { font-weight: 400; font-size: 26px; line-height: 34px; letter-spacing: 0.2px; }
  & h5 { font-weight: bold; font-size: 20px; line-height: 26px; letter-spacing: 0.2px; }
  & h6 { font-weight: 400; font-size: 18px; line-height: 24px; letter-spacing: 0.1px; }
  & p { font-size: 16px; }
`