import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { Container, Space, Text, Stack, Box } from "@x-apps-projetos/design-system"
import Logo from "@components/Logo"

export const SectionFooter = React.memo(({ content }) => {
  return (
    <Box id="section-footer" xs={{
      "width": "100%",
      "background": "primary-dark"
    }}>
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space xs={{ "py": "64", "expand": true }}>
          <Stack
            xs={{
              "direction": "column",
              "spacing": "24",
              "align": "center"
            }}
          >
            <Logo
              sx={{
                width: "100%",
                svg: {
                  width: ["109px", "109px", "122px"],
                  margin: "0 auto",
                },
              }}
            />
            <Text
              as="span"
              xs={{ "variant": "body_1", "color": "white", "align": "center" }}
              value={content.copyright}
            />
          </Stack>
        </Space>
      </Container>
    </Box>
  )
})