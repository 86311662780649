import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { Container, Space, Row, Col, Box, Stack, Text } from "@x-apps-projetos/design-system"
import { Testimonials } from "@components/_v2/_shared/testimonials"
import { Customers } from "@components/_v2/_shared/customers"
import { parseHtml } from "../../utils/parsehtml"


const margin = 100

export const SectionTestimonials = React.memo(({ content }) => {
  return (
    <Box id="section-testimonials" xs={{
      "width": "100%",
      "background": "primary",
      "marginTop": `${margin}px`
    }}>
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>

        <Box xs={{
          "background": "white",
          "marginTop": `-${margin}px`,
          "radius": "medium",
          "shadow": "dropped"
        }}>
          <Space xs={{ "py": "32", "px": "64", "expand": true }}>
            <Stack xs={{ "direction": "column", "spacing": "24", "align": "center" }}>
              <Text
                as="h5"
                xs={{ "variant": "h5", "color": "primary", "align": "center" }}
                value={parseHtml(content.title)}
              />
              <Stack xs={{ "direction": "row", "spacing": "42", "justify": "space-between", "align": "center" }}>
                <Customers />
              </Stack>
            </Stack>
          </Space>
        </Box>

        <Space xs={{ "pt": "64", "pb": "32", "expand": true }}>
          <Row>
            <Col xs={12}>
              <Testimonials alignDots="center" />
            </Col>
          </Row>
        </Space>
      </Container>
    </Box>
  )
})