import React from "react"
import LottieAnimation from "@components/LottieAnimation"
import { Stack, Box, Text, Space } from "@x-apps-projetos/design-system"
import { IndexIndicator } from "./style"
import { parseHtml } from "@components/_v2/devsquad-landing-page/utils/parsehtml"

export const Step = ({ index, title, description, animation }) => {
  return (
    <Stack xs={{ "direction": "row", "spacing": "64", "align": "center" }}>
      <Space xs={{
        "expand": true,
        "px": "64"
      }}>
        <Stack xs={{ "direction": "row", "spacing": "32" }}>
          <IndexIndicator xs={{ "background": "primary", }}>
            <Text
              xs={{ "color": "white", "variant": "subtitle_1" }}
              value={index}
            />
          </IndexIndicator>
          <Stack xs={{ "direction": "column", "spacing": "24" }}>
            <Text
              as="h4"
              xs={{ "variant": "h4", "color": "primary" }}
              value={parseHtml(title)}
            />
            <Text
              as="span"
              xs={{ "variant": "body_1", "color": "primary" }}
              value={parseHtml(description)}
            />
          </Stack>
        </Stack>
      </Space>
      <Box xs={{ "padding": "24", "background": "white", "shadow": "dropped", "radius": "small", "width": "100%" }}>
        <LottieAnimation
          animation={animation}
          height="100%"
          width="100%"
        />
      </Box>
    </Stack>
  )
}