import React from "react"
import { Wrapper, TechLogoContainer } from "./style"
import { CONSTANTS } from "../../utils/constants"
import { Box, Container, Space, Stack, Text, Button, Center } from "@x-apps-projetos/design-system"
import logoAirbnb from "./assets/logo-airbnb.svg"
import logoGoogle from "./assets/logo-google.svg"
import logoPaypal from "./assets/logo-paypal.svg"
import logoUber from "./assets/logo-uber.svg"
import { SvgUrl } from "@components/_v2/_shared/svg-url"
import { replaceTokenTech } from "../../utils/tokentech"
import { parseHtml } from "../../utils/parsehtml"

export const SectionTech = React.memo(({ lp, content }) => {

  const skill = lp.related_skills[0]

  return (
    <Wrapper id="section-tech">
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "pt": "160", "pb": "64" }}
        >
          <Stack xs={{ "direction": "row", "align": "center" }}>
            <Box xs={{ "background": "primary", "width": "100%", "padding": "32", "shadow": "dropped" }}>
              <Stack xs={{ "direction": "column", "spacing": "24", "align": "center" }}>
                <Text
                  xs={{ "color": "white", "align": "center", "variant": "body_1" }}
                  value={parseHtml(content.title)}
                />
                <Text
                  as="h2"
                  xs={{ "color": "white", "align": "center", "variant": "h2" }}
                  value={skill.nome}
                />
                <Text
                  xs={{ "color": "white", "align": "center", "variant": "body_2" }}
                  value={parseHtml(replaceTokenTech(content.description, skill.nome))}
                />
              </Stack>
            </Box>
            <Box xs={{ "background": "white", "width": "100%", "shadow": "dropped", "radius": "medium", "padding": "32" }}>
              <Stack xs={{ "direction": "column", "spacing": "24", "align": "center" }}>
                <TechLogoContainer
                  xs={{
                    "background": "white",
                    "width": "180px",
                    "height": "180px",
                    "shadow": "dropped",
                    "marginTop": "-128px",
                    "padding": "16"
                  }}
                >
                  <Center>
                    <SvgUrl
                      size={112}
                      url={`https://x-apps.com.br/contrate-squads/logos/${skill.id}.svg`}
                    />
                  </Center>
                </TechLogoContainer>
                <Stack xs={{ "direction": "row", "spacing": "16", "align": "center", "justify": "center" }}>
                  <img src={logoPaypal} alt="Paypal" />
                  <img src={logoAirbnb} alt="AirBnb" />
                  <img src={logoUber} alt="Uber" />
                  <img src={logoGoogle} alt="Google" />
                </Stack>
                <Text
                  xs={{ "color": "dark", "align": "center", "variant": "body_2" }}
                  value={parseHtml(replaceTokenTech(content.companies_description, skill.nome))}
                />
                <Button
                  wrap={true}
                  size="medium"
                  color="secondary-gradient"
                  label={replaceTokenTech(content.cta_button_label, skill.nome)}
                />
              </Stack>
            </Box>
          </Stack>
        </Space>
      </Container>
    </Wrapper>
  )
})