import { graphql } from "gatsby"
import React from "react"
import { DevsquadLandingPage } from "@components/_v2/devsquad-landing-page"
import SEO from "@components/Seo/Seo.component"
import { useStoreCampaignVariablesInCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"

import { ThemeProvider, DEFAULT_THEME } from "@x-apps-projetos/design-system"
import "@x-apps-projetos/design-system/dist/esm/index.css"

function LandingPageDevsquadTemplate({ data }) {
  const {
    lp: { frontmatter: lp },
    content: { frontmatter: content },
  } = data
  useStoreCampaignVariablesInCookies()

  return (
    <ThemeProvider theme={DEFAULT_THEME}>
      <DevsquadLandingPage lp={lp} content={content}>
        <SEO
          keywords={lp.keywords}
          title={lp.plainTitle}
          description={lp.plainSubtitle}
        />
      </DevsquadLandingPage>
    </ThemeProvider>
  )
}

export const query = graphql`
  query LandingPagesDevsquadV2($slug: String!, $language: String!) {
    lp: markdownRemark(
      fileAbsolutePath: { glob: "**/content/devsquad-landing-pages-v2/*.md" }
      fields: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title: markdownField(from: "title")
        subtitle: markdownField(from: "subtitle")
        plainTitle: plainText(from: "title")
        plainSubtitle: plainText(from: "subtitle")
        related_skills {
          id
          nome
        }        
        slug
      }
    },
    content: markdownRemark(
      fileAbsolutePath: { glob: "**/content/devsquad-landing-pages-v2-shared/*.md" }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        language
        mautic_form_id
        section_header {
          links {
            label
            section
            evidence
          }
        }
        section_hero {
          cta_button_label
        }
        section_tech {
          title
          description
          list_companies {
            company_logo {
              childSvg {
                content
              }
            }
          }
          companies_description
          cta_button_label
        }
        section_video {
          title
          video_url
          cta_button_label
        }
        section_about {
          title
          subtitle
          first_step {
            title
            description
          }
          second_step {
            title
            description
          }
          third_step {
            title
            description
          }
        }
        section_testimonials {
          title
        }
        section_advantages {
          title
          subtitle
          cta_button_label
          advantages {
            name
            description
            illustration {
              childSvg {
                content
              }
            }
          }
        }
        section_faq {
          title
        }
        section_other_skills {
          description
          cta_button_label
          list_skills {
            id
            nome
          }
        }
        section_hot_skills {
          description
          online_customers_label
          cta_button_label
          list_skills {
            id
            nome
          }
        } 
        section_footer {
          copyright
        }
      }
    }
  }
`

export default LandingPageDevsquadTemplate
