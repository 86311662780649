import React from "react"
import { DevsquadLandingPageController } from "./controller"
import { DevsquadLandingPageView } from "./view"

export function DevsquadLandingPage({ children, ...props }) {
  return (
    <DevsquadLandingPageController {...props}>
      <DevsquadLandingPageView {...props}>
        {children}
      </DevsquadLandingPageView>
    </DevsquadLandingPageController>
  )
}
