import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
  background-color: #F5F5F5;
`