import styled from "@emotion/styled"

export const ItemWrap = styled.div`
  & svg {
    height: auto;
    flex: 1;
    width: 100%;
    & path {
      fill: ${props => props.color};    
    }
  }
`