import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { Svg } from "@components/_v2/_shared/svg"
import { parseHtml } from "../../utils/parsehtml"
import { DevsquadLandingPageContext } from "../../context"
import { Wrapper, TabButton, AdvantageDescription } from "./style"
import { Container, Space, Text, Row, Col, Stack, Button, Box } from "@x-apps-projetos/design-system"

export const SectionAdvantages = React.memo(({ lp, content, section_other_skills }) => {

  const { navigateToMarket } = React.useContext(DevsquadLandingPageContext)
  const [selectedAdvantage, setSelectedAdvantage] = React.useState(content.advantages[0])

  return (
    <Wrapper id="section-advantages">
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "pt": "64", "pb": "64" }}
        >
          <Stack xs={{ "direction": "column", "spacing": "64" }}>
            <Row>
              <Col xs={2}></Col>
              <Col xs={8}>
                <Stack
                  xs={{
                    "direction": "column",
                    "spacing": "36",
                    "align": "center"
                  }}
                >
                  <Text
                    as="h2"
                    xs={{ "variant": "h2", "color": "primary", "align": "center" }}
                    value={parseHtml(content.title)}
                  />
                  <Text
                    as="h3"
                    xs={{ "variant": "h3", "color": "primary", "align": "center" }}
                    value={parseHtml(content.subtitle)}
                  />
                </Stack>
              </Col>
            </Row>

            <Stack xs={{ "direction": "row", "spacing": "16" }}>

              {content.advantages.map((advantage) => (
                <TabButton
                  fill="underline"
                  shape="flat"
                  size="medium"
                  label={advantage.name}
                  color={selectedAdvantage.name === advantage.name ? "primary" : "muted"}
                  onClick={() => setSelectedAdvantage(advantage)}
                />
              ))}
            </Stack>

            {selectedAdvantage && (
              <Space xs={{ "expand": true, "px": "32" }}>
                <Stack xs={{ "direction": "row", "spacing": "64", "align": "center" }}>
                  <Svg height={347} value={selectedAdvantage.illustration.childSvg.content} />
                  <Stack xs={{ "direction": "column", "spacing": "32" }}>
                    <AdvantageDescription
                      dangerouslySetInnerHTML={{ __html: parseHtml(selectedAdvantage.description) }}
                    />
                    <Button
                      wrap={true}
                      color="secondary-gradient"
                      onClick={() => navigateToMarket(lp.related_skills)}
                      label={content.cta_button_label}
                    />
                  </Stack>
                </Stack>
              </Space>
            )}

            <Row>
              <Col xs={2}></Col>
              <Col xs={8}>
                <Box xs={{
                  "background": "white",
                  "radius": "medium",
                  "shadow": "dropped",
                  "width": "100%"
                }}>
                  <Space xs={{ "py": "32", "px": "64", "expand": true }}>
                    <Stack xs={{ "direction": "column", "spacing": "24", "align": "center" }}>
                      <Text
                        as="h5"
                        xs={{ "variant": "h5", "color": "primary", "align": "center" }}
                        value={parseHtml(section_other_skills.description)}
                      />
                      <Stack xs={{ "direction": "row", "spacing": "12", "justify": "space-between", "align": "center" }}>
                        {section_other_skills.list_skills.map(skill => (
                          <Button
                            fill="none"
                            shape="rounded"
                            symetrycSize={52}
                            onClick={() => navigateToMarket([skill])}
                          >
                            <img
                              key={`skill-${skill.id}`}
                              width="42"
                              height="42"
                              src={`https://x-apps.com.br/contrate-squads/logos/${skill.id}.svg`}
                            />
                          </Button>
                        ))}
                      </Stack>
                      <Button
                        wrap={true}
                        color="secondary-gradient"
                        label={section_other_skills.cta_button_label}
                        onClick={() => navigateToMarket(lp.related_skills)}
                      />
                    </Stack>
                  </Space>
                </Box>
              </Col>
            </Row>

          </Stack>
        </Space>
      </Container>
    </Wrapper>
  )
})