import React from "react"
import { Wrapper } from "./style"
import { CONSTANTS } from "../../utils/constants"
import deskStep1 from "./assets/desk-step-1.json"
import deskStep2 from "./assets/desk-step-2.json"
import deskStep3 from "./assets/desk-step-3.json"


import { Container, Space, Text, Row, Col, Stack } from "@x-apps-projetos/design-system"
import { Step } from "./components/step/index"
import { parseHtml } from "../../utils/parsehtml"

export const SectionAbout = React.memo(({ content }) => {
  return (
    <Wrapper id="section-about">
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "pt": "64", "pb": "64" }}
        >
          <Stack xs={{ "direction": "column", "spacing": "64" }}>
            <Row>
              <Col xs={2}></Col>
              <Col xs={8}>
                <Stack
                  xs={{
                    "direction": "column",
                    "spacing": "36",
                    "align": "center"
                  }}
                >
                  <Text
                    as="h2"
                    xs={{ "variant": "h2", "color": "primary", "align": "center" }}
                    value={parseHtml(content.title)}
                  />
                  <Text
                    as="h3"
                    xs={{ "variant": "h3", "color": "primary", "align": "center" }}
                    value={parseHtml(content.subtitle)}
                  />
                </Stack>
              </Col>
            </Row>

            <Step
              index={1}
              title={content.first_step.title}
              description={content.first_step.description}
              animation={deskStep1}
            />

            <Step
              index={2}
              title={content.second_step.title}
              description={content.second_step.description}
              animation={deskStep2}
            />

            <Step
              index={3}
              title={content.third_step.title}
              description={content.third_step.description}
              animation={deskStep3}
            />

          </Stack>

        </Space>
      </Container>
    </Wrapper>
  )
})