import styled from "@emotion/styled"
import { Box } from "@x-apps-projetos/design-system"

export const IndexIndicator = styled(Box)`
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 32px;
  align-items:center;
  justify-content: center;
  margin: 6px;
`