import React from "react"
import { TechLogoContainer, Wrapper, SubtitleContainer } from "./style"
import { CONSTANTS } from "../../utils/constants"
import { Container, Space, Stack, Text, Box, Button, Center, Icon } from "@x-apps-projetos/design-system"
import { SvgUrl } from "@components/_v2/_shared/svg-url"
import { Bounce, Flip } from 'react-reveal';
import remark from "remark"
import remarkHTML from "remark-html"
import { DevsquadLandingPageContext } from "../../context"
const remarkInstance = remark().use(remarkHTML)

export const SectionHero = React.memo(({ lp, content }) => {

  const skill = lp.related_skills[0]
  const { navigateToMarket } = React.useContext(DevsquadLandingPageContext)

  return (
    <Wrapper id="section-hero">
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "py": "64" }}
        >
          <Stack
            xs={{ "direction": "column-reverse", "align": "center", "justify": "center", "spacing": "64" }}
            md={{ "direction": "row" }}
          >
            <Stack xs={{ "direction": "column", "spacing": "24", "justify": "center", "align": "flex-start" }}>
              <Box
                xs={{ "display": "none" }}
                md={{ "display": "block" }}
              >
                <Text
                  as="h1"
                  value={lp.title}
                  md={{ "color": "white", "variant": "h2" }}
                  lg={{ "variant": "h2" }}
                />
              </Box>
              <SubtitleContainer
                dangerouslySetInnerHTML={{ __html: remarkInstance.processSync(lp.subtitle).toString() }}
              />
              <Button
                label={content.cta_button_label}
                color="secondary-gradient"
                wrap={true}
                onClick={() => navigateToMarket(lp.related_skills)}
                rightComponent={<Icon iconName="FiArrowRight" size={14} color="white" />}
              />
            </Stack>

            <Stack xs={{ "align": "center", "justify": "center" }}>
              <Bounce>
                <TechLogoContainer
                  xs={{
                    "background": "white",
                    "width": "410px",
                    "height": "410px",
                    "shadow": "dropped",
                    "padding": "16",
                  }}
                >
                  <Center>
                    <Flip bottom>
                      <SvgUrl
                        size={320}
                        url={`https://x-apps.com.br/contrate-squads/logos/${skill.id}.svg`}
                      />
                    </Flip>
                  </Center>
                </TechLogoContainer>
              </Bounce>
            </Stack>
          </Stack>
        </Space>
      </Container>
    </Wrapper>
  )
})