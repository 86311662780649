import React from "react"
import { WrapSvg } from "./style"
import { Text } from "@x-apps-projetos/design-system"

export const Svg = ({ value, height = 64 }) => {
  return (
    <WrapSvg height={height}>
      <Text value={value} />
    </WrapSvg>
  )
}