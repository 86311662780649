import React from "react"
import { Wrapper } from "./style"
import { SectionHeader } from "./components/section-header"
import { SectionHero } from "./components/section-hero"
import { SectionTech } from "./components/section-tech"
import { SectionVideo } from "./components/section-video"
import { SectionAbout } from "./components/section-about"
import { SectionFooter } from "./components/section-footer"
import { SectionAdvantages } from "./components/section-advantages"
import { SectionTestimonials } from "./components/section-testimonials"
import { SectionFaq } from "./components/section-faq"
import { SectionHotSkills } from "./components/section-hot-skills"

export function DevsquadLandingPageView({
  children,
  lp,
  content: {
    section_header,
    section_hero,
    section_tech,
    section_video,
    section_about,
    section_testimonials,
    section_advantages,
    section_faq,
    section_other_skills,
    section_hot_skills,
    section_footer
  }
}) {

  return (
    <Wrapper>
      {children}
      <SectionHeader lp={lp} content={section_header} />
      <SectionHero lp={lp} content={section_hero} />
      <SectionTech lp={lp} content={section_tech} />
      <SectionVideo lp={lp} content={section_video} />
      <SectionAbout content={section_about} />
      <SectionTestimonials content={section_testimonials} />
      <SectionAdvantages lp={lp} content={section_advantages} section_other_skills={section_other_skills} />
      <SectionFaq content={section_faq} />
      <SectionHotSkills lp={lp} content={section_hot_skills} />
      <SectionFooter content={section_footer} />
    </Wrapper>
  )
}
