import React from "react"
import { Wrapper } from "./style"
import { CONSTANTS } from "../../utils/constants"
import { Container, Space, Text, Row, Col, Stack, Box, Button, Icon } from "@x-apps-projetos/design-system"
import ReactPlayer from 'react-player'
import { parseHtml } from "../../utils/parsehtml"
import { replaceTokenTech } from "../../utils/tokentech"
import { DevsquadLandingPageContext } from "../../context"

export const SectionVideo = React.memo(({ lp, content }) => {

  const skill = lp.related_skills[0]
  const { navigateToMarket } = React.useContext(DevsquadLandingPageContext)

  return (
    <Wrapper id="section-video">
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "py": "32" }}
        >
          <Row>
            <Col xs={2}></Col>
            <Col xs={8}>
              <Stack
                xs={{
                  "direction": "column",
                  "spacing": "36",
                  "align": "center"
                }}
              >
                <Text
                  as="h2"
                  xs={{ "variant": "h2", "color": "primary", "align": "center" }}
                  value={parseHtml(replaceTokenTech(content.title, skill.nome))}
                />
                <Box
                  xs={{ "shadow": "dropped", "radius": "small", "background": "primary" }}
                  style={{ "overflow": "hidden" }}
                >
                  <ReactPlayer
                    light={true}
                    controls={false}
                    style={{ "borderRadius": "12px" }}
                    url={content.video_url}
                  />
                </Box>
                <Button
                  wrap={true}
                  center={true}
                  shadow="dropped"
                  shape="pill"
                  color="secondary-gradient"
                  onClick={() => navigateToMarket(lp.related_skills)}
                  label={replaceTokenTech(content.cta_button_label, skill.nome)}
                  rightComponent={<Icon iconName="FiArrowRight" size={14} color="white" />}
                />
              </Stack>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Space>
      </Container>
    </Wrapper>
  )
})