import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { Container, Box, Text, Space, Stack } from "@x-apps-projetos/design-system"
import { Faq } from "@components/_v2/_shared/faq"
import { parseHtml } from "../../utils/parsehtml"

export const SectionFaq = React.memo(({ content }) => {
  return (
    <Box id="section-faq" xs={{ "width": "100%" }}>
      <Container maxWidth={CONSTANTS.MAX_WIDTH}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "pt": "64", "pb": "64" }}
        >
          <Stack xs={{ "direction": "column", "spacing": "64", "justify": "center", "align": "center" }}>
            <Text
              as="h2"
              xs={{ "variant": "h2", "color": "primary", "align": "center" }}
              value={parseHtml(content.title)}
            />
            <Faq />
          </Stack>
        </Space>
      </Container>
    </Box>
  )
})