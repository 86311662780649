import styled from "@emotion/styled"
import { Button } from "@x-apps-projetos/design-system"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;  
`

export const TabButton = styled(Button)`
  border-bottom-width: 5px !important;
`

export const AdvantageDescription = styled.div`
  font-family: "x-apps";
  display: flex;
  flex-direction: column;
  gap: 24px;
  & h1 { font-weight: bold; font-size: 56px; line-height: 70px; letter-spacing: 0.4px; color: #00346B; }
  & h2 { font-weight: bold; font-size: 40px; line-height: 46px; letter-spacing: 0.2px; color: #00346B; }
  & h3 { font-weight: 400; font-size: 28px; line-height: 34px; letter-spacing: 0.2px; color: #00346B; }
  & h4 { font-weight: 400; font-size: 26px; line-height: 34px; letter-spacing: 0.2px; color: #00346B; }
  & h5 { font-weight: bold; font-size: 20px; line-height: 26px; letter-spacing: 0.2px; color: #00346B; }
  & h6 { font-weight: 400; font-size: 18px; line-height: 24px; letter-spacing: 0.1px; color: #00346B; }
  & p { font-size: 16px; color: #333333; }
`