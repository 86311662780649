import React from "react"
import { WrapSvg } from "./style"
import { ReactSVG } from "react-svg"

export const SvgUrl = ({ color, size = 42, url }) => {
  return (
    <WrapSvg color={color}>
      <ReactSVG
        src={url}
        // fallback={() => <DefaultSkillLogo />}
        // loading={() => <DefaultSkillLogo />}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        beforeInjection={(svg) => {
          svg.setAttribute('width', String(size));
          svg.setAttribute('height', String(size));
          svg.setAttribute('style', "display:flex;align-items:center;justify-content:center;");
        }}
      />
    </WrapSvg>
  )
}