import React from "react"
import { ItemWrap } from "./style"
import { graphql, useStaticQuery } from "gatsby"
import { Text, useTheme } from "@x-apps-projetos/design-system"

export const Customers = () => {

  const theme = useTheme()
  const { content } = useStaticQuery(graphql`
    query {
      content: allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/content/clients/*.md" } }
        sort: { fields: frontmatter___order }
        limit: 8
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              backgroundColor
              linkToCase
              logo {
                childSvg {
                  content
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    content.edges.map(item => (
      <ItemWrap color={theme.color.gray}>
        <Text value={item.node.frontmatter.logo.childSvg.content} />
      </ItemWrap>
    ))
  )
}