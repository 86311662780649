import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import remark from "remark"
import remarkHTML from "remark-html"
import { useTheme, Text, Accordion, Icon } from "@x-apps-projetos/design-system"

const remarkInstance = remark().use(remarkHTML)

export const Faq = () => {

  const theme = useTheme()
  const { content } = useStaticQuery(graphql`
    query {
      content: allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/content/faq/*.md" } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            id
            frontmatter {
              pergunta
              resposta
            }
          }
        }
      }
    }
  `)

  const questions = content.edges.map((question) => ({
    title: (props) => (
      <Text
        value={remarkInstance.processSync(question.node.frontmatter.pergunta).toString()}
        xs={{
          "color": props.expanded ? "link" : "dark",
          "variant": "h6"
        }}
      />
    ),
    content: (props) => (
      <Text
        xs={{ "color": "dark", "variant": "body_1" }}
        value={remarkInstance.processSync(question.node.frontmatter.resposta).toString()}
      />
    )
  }))

  return (
    <Accordion
      xs={{
        "backgroundColor": "white",
        "borderColor": "transparent",
        "padding": "16"
      }}
      minimizedIcon={<Icon iconName="FiPlus" size={16} color={theme.color.dark} />}
      items={questions}
    />
  )
}